import React from "react"
import {
  Button,
  Flex,
  HStack,
  Image,
  Show,
  Link as ChakraLink,
} from "@chakra-ui/react"
import theme from "../../../Themes"

export default function Header() {
  return (
    <Flex
      px="1rem"
      w="100%"
      maxWidth="1152px"
      m="0 auto"
      position="absolute"
      top="10"
      left="0"
      right="0"
      zIndex={5}
      bg="transparent"
      justifyContent="space-between"
    >
      <Show above="355px">
        <Flex alignItems="center">
          {/* <ChakraLink
            href="/"          
          >
            <Image
              maxWidth={{ base: "100px", sm: "120px", md: "143px" }}
              src={theme.logo.type2.url}
              alt="Logo da CSG"
            />
          </ChakraLink> */}
        </Flex>
      </Show>
      <HStack gap="1.25rem" ml="1.25rem">
        <ChakraLink
          href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/login`}
          target="_blank"
        >
          <Button
            size={{ base: "md", sm: "md", md: "md", lg: "lg" }}
            variant="outline"
            bg="transparent"
            color={theme.color.quaternary}
            borderRadius="0.375rem"
            border="none"
            fontSize="1rem"
            fontWeight="600"
            _hover={{
              bg: `transparent`,
              color: `${theme.color.quaternary}`,
            }}
          >
            Login
          </Button>
        </ChakraLink>
        <ChakraLink
          href={`${process.env.REACT_APP_WEBAPP_BASE_URL}/cadastrar`}
          target="_blank"
        >
          <Button
            size={{ base: "md", sm: "md", md: "md", lg: "lg" }}
            variant="solid"
            bg={theme.color.primary}
            color={theme.color.quaternary}
            borderRadius="0.375rem"
            border="none"
            fontSize="1rem"
            fontWeight="600"
            _hover={{
              bg: `${theme.color.primary}`,
              color: `${theme.color.quaternary}`,
            }}
          >
            Criar conta
          </Button>
          </ChakraLink>
      </HStack>
    </Flex>
  )
}
