import { Role } from "./Role"

export class User {
  public id = 0
  public roleId = 0
  public role = new Role()  
  public type = ''
  public email = ''
  public password = ''
  public passwordConfirm = ''
  public firstname = ''
  public lastname = ''
  public companyName = ''
  public businessName = ''
  public document = ''
  public postcode = ''
  public address1 = ''
  public addressNumber = ''
  public addressComplement = ''
  public address2 = ''
  public city = ''
  public state = ''
  public phone = ''
  public post = ''
  public isActive = false

  constructor(data: any = {}) {
    this.id = data.id ? parseInt(data.id) : 0
    this.roleId = data.roleId ? parseInt(data.roleId) : 0
    this.role = new Role(data.role)
    this.type = data.type || ''
    this.email = data.email || ''    
    this.password = data.password || ''
    this.passwordConfirm = data.passwordConfirm || ''
    this.firstname = data.firstname || ''
    this.lastname = data.lastname || ''
    this.companyName = data.companyName || ''
    this.businessName = data.businessName || ''
    this.document = data.document || ''
    this.postcode = data.postcode || ''
    this.address1 = data.address1 || ''
    this.addressNumber = data.addressNumber || ''
    this.addressComplement = data.addressComplement || ''
    this.address2 = data.address2 || ''
    this.city = data.city || ''
    this.state = data.state || ''
    this.phone = data.phone || ''
    this.post = data.post || ''
    this.isActive = data.isActive === true
  }
}