import { Charge, SearchResult } from "../../entities"
import { ChargeRepository } from "../../repositories/csg/ChargeRepository"


export class ChargeService {
  public static async searchByPlate(licensePlate: string): Promise<SearchResult<Charge>> {
    return await ChargeRepository.searchByPlate(licensePlate, '')
  }

  public static async searchByPlates(licensePlates: string[], status: string|null): Promise<SearchResult<Charge>> {
    return await ChargeRepository.searchByPlates(licensePlates, status, '')
  }
}